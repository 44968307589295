<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Опросы</h5>
            <tricolor-line />
            <div class="contain">
                <survey-maps v-for="(survey, key) in surveys" :key="`link${key}`" :card="survey" class="survey-maps"/>
            </div>
            <pagination-block class="news__pagination" :current="currentPage" :total="getTotalPage" @page-changed="getPage" />
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import SurveyMaps from '@/components/activitiesSurveys/SurveyMaps'
import Pagination from '@/components/Pagination'
export default {
  name: 'SurveysActivities',
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'survey-maps': SurveyMaps,
    'pagination-block': Pagination
  },
  data () {
    return {
      currentPage: 1,
      countPage: 50,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Опросы'
        }
      ],
      surveys: [
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья!',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        },
        {
          date: '01.01.2020',
          title: 'Инклюзия в детском отдыхе: барьеры и пути их преодоления',
          subtitle: 'Дорогие друзья! Современная концепция инклюзивного отдыха в России сегодня только ...',
          type: 'question.svg',
          link: '/'
        }
      ]
    }
  },
  computed: {
    getTotalPage () {
      return this.$store.getters.getContent.total ? this.$store.getters.getContent.total : 50
    }
  },
  methods: {
    getPage (page) {
      console.log(page)
      this.currentPage = page
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: auto;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem 0.625rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 1.81rem;
}

.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem -1.06rem 0 -1.06rem;
}
.survey-maps{
    margin: 0 1.06rem 1.87rem 1.06rem;
    width: calc(33.333% - 2.125rem);
}
.news__pagination{
    margin-top: 0.625rem;
    padding: 2.5rem 0 1.87rem 0;
    border-top: .0625rem solid #D5D6D9;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .survey-maps{
    margin: 0 1.06rem 1.87rem 1.06rem;
    width: calc(50% - 2.125rem);
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .survey-maps{
      margin: 0 1.06rem 1.87rem 1.06rem;
      width: calc(100% - 2.125rem);
    }
}
</style>
