<template>
    <div class="info-banner">
        <span class="info-banner__type" :style="`background: url('${'/assets/icons/' + card.type}') center / cover`"></span>
        <p class="info-banner__date">{{ dateArr(card.date) }}</p>
        <p class="info-banner__title">{{ card.title }}</p>
        <p class="info-banner__text" v-trim="2">{{ card.subtitle }}</p>
        <router-link  :to="card.link"> Пройти опрос </router-link>
    </div>
</template>

<script>
// import formatDate from '@/mixins/formatDate'
export default {
  name: 'SurveyMaps',
  // mixins: [formatDate],
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({
        date: null,
        links: null,
        subtitle: null,
        title: null,
        type: null
      })
    }
  },
  methods: {
    dateArr (date) {
      const arr = date.slice(0, 10).split('.')
      return (new Date(arr[2], arr[1] - 1, arr[0]).toLocaleString((this.$store.getters.GET_LANG ? 'ru-ru' : 'en-US'), { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    }
  }
}
</script>

<style scoped lang="scss">
.info-banner {
  margin-bottom: 1.88rem;
  padding: 1.875rem 1.875rem 1.375rem 1.875rem;
  width: 26.56rem;
  min-height: 18.9375rem;

  box-shadow: 0 .94rem 1.56rem rgba(189, 205, 221, 0.35);
  border-radius: .5rem;
  border: .06rem solid #E5E8EE;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;

  &__type {
    width: 2.8125rem;
    height: 2.8125rem;

    margin-bottom: 1.25rem;
  }
  &__date{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    margin-bottom: .312rem;
  }

  &__text{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #171717;
    opacity: 0.8;
  }

    a {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.25rem;
        color: #FFFFFF;
        background: #246CB7;
        padding: .5rem 1.88rem .44rem 1.88rem;
        border-radius: .187rem;
        margin-top: auto;
    }
}

@media screen and (max-width: 768px){
  .info-banner{
    a {
      width: 100%;
      text-align: center;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .info-banner {
        &__title {
            width: 100% !important;
        }
    }
}

</style>
